/* ProjectList.css */

.project-list {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .project-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .project-item .group-number {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .project-item .project-details {
    margin-left: 20px;
  }
  
  .project-item .project-details > div {
    margin-bottom: 10px;
  }
  
  .project-item .group-members {
    list-style-type: none;
    padding-left: 0;
  }
  
  .project-item .member {
    margin-bottom: 5px;
  }
  
  .project-item .member strong {
    color: #333;
  }
  
  .project-item .member a {
    color: #007bff;
    text-decoration: none;
  }
  
  .project-item .member a:hover {
    text-decoration: underline;
  }
  
  .project-link {
    margin-top: 10px;
  }
  
  .project-link a {
    display: inline-block;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .project-link a:hover {
    background-color: #45a049;
  }
  