.project-list {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.project-list h2 {
  text-align: center;
  margin-bottom: 20px;
}

.project-list ul {
  list-style-type: none;
  padding: 0;
}

.project-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.group-number {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.project-details {
  margin-bottom: 12px;
}

.group-members {
  font-size: 14px;
}

.member {
  display: inline-block;
  padding: 3px 8px;
  background-color: #e0e0e0;
  border-radius: 3px;
  margin-right: 5px;
}

.member:last-child {
  margin-right: 0;
}
